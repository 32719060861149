<template>
  <div class="modal fade " id="productItemAddModal" tabindex="-1" role="dialog" aria-labelledby="productItemAddModal"
       aria-hidden="true" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog  modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Add Item</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="clear_data">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <h5 class="my-1 text-uppercase">Basic Info</h5>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="controls">
                    <label>Select Product</label>
                    <VueMultiselect :allow-empty="false" v-model="selectedProduct" class="multiselect-blue"
                                    :options="productOptions"
                                    :close-on-select="true" placeholder="Select Status" label="name" track-by="name"
                                    :show-labels="false" :disabled="true"/>

                  </div>
                  <div class="text-danger" v-if="errors.product_id">{{ errors.product_id }}</div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="controls">
                    <label>Select Warehouse</label>
                    <VueMultiselect :allow-empty="false" v-model="selectedWarehouse" class="multiselect-blue"
                                    :options="warehouseOptions"
                                    :close-on-select="true" placeholder="Select Status" label="name" track-by="name"
                                    :show-labels="false"/>
                  </div>
                  <div class="text-danger" v-if="errors.warehouse_id">{{ errors.warehouse_id }}</div>
                </div>

              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="">
                    <label>Serial No</label>
                    <input v-model="postProductItemData.serial_number" type="text" class="form-control"
                           placeholder="Enter Serial No">
                  </div>
                </div>

              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="">
                    <label>Model No</label>
                    <input v-model="postProductItemData.model_number" type="text" class="form-control"
                           placeholder="Enter Model No">
                  </div>
                </div>

              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="">
                    <label>SKU</label>
                    <input v-model="postProductItemData.sku" type="text" class="form-control"
                           placeholder="Enter SKU">
                  </div>
                </div>

              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="">
                    <label>Bar Code</label>
                    <input v-model="postProductItemData.barcode" type="text" class="form-control"
                           placeholder="Enter Bar Code">
                  </div>
                </div>

              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="">
                    <label>QR Code</label>
                    <input v-model="postProductItemData.qr_code" type="text" class="form-control"
                           placeholder="Enter QR Code">
                  </div>
                </div>

              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="">
                    <label>Manufacturing Code</label>
                    <input v-model="postProductItemData.manufacturing_code" type="text" class="form-control"
                           placeholder="Enter Manufacturing Code">
                  </div>
                </div>

              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="controls">
                    <label>Select State</label>
                    <VueMultiselect :allow-empty="false" v-model="selectedState" class="multiselect-blue"
                                    :options="stateOptions"
                                    :close-on-select="true" placeholder="Select Status" label="name" track-by="name"
                                    :show-labels="false" :disabled="true"/>
                  </div>
                  <div class="text-danger" v-if="errors.state">{{ errors.state }}</div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="controls">
                    <label>Select Vendor</label>
                    <VueMultiselect :allow-empty="false" v-model="selectedVendor" class="multiselect-blue"
                                    :options="vendorOptions"
                                    :close-on-select="true" placeholder="Select Status" label="name" track-by="name"
                                    :show-labels="false"/>
                  </div>
                  <div class="text-danger" v-if="errors.vendor_id">{{ errors.vendor_id }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="">
                    <label>Purchase Price</label>
                    <input v-model="postProductItemData.purchase_cost" type="number" class="form-control"
                           placeholder="Enter Purchase Cost">
                  </div>
                </div>

              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <div class="controls">
                    <label>Status</label>
                    <VueMultiselect :allow-empty="false" v-model="selectedCondition" class="multiselect-blue"
                                    :options="conditionOptions"
                                    :close-on-select="true" placeholder="Select Status" label="name" track-by="name"
                                    :show-labels="false" :disabled="true"/>
                  </div>

                </div>
              </div>
              <!--            start licence info section   -->
              <div class="col-12">
                <h5 class="my-1 text-uppercase"> Licence Info </h5>
                <div class="row">
                  <div class="col-12 col-md-2">
                    <div class="form-group">
                      <fieldset class="mt-2">
                        <div class="checkbox checkbox-primary">
                          <input type="checkbox" class="" id="hasLicence" v-model="hasLicence">
                          <label for="hasLicence">Has Licence</label>
                        </div>
                      </fieldset>
                    </div>
                  </div>
                  <div v-if="hasLicence" class="col-md-10 d-flex">
                    <div class="col-12 col-md-4">
                      <div class="form-group">
                        <div class="controls">
                          <label>Licence Type</label>
                          <VueMultiselect :allow-empty="false" v-model="selectedLicenceType" class="multiselect-blue"
                                          :options="licenceTypeOptions"
                                          :close-on-select="true" placeholder="Select Licence type" label="name"
                                          track-by="name"
                                          :show-labels="false"/>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="form-group">
                        <div class="controls">
                          <label>Period</label>
                          <VueMultiselect v-model="selectedPeriod" :allow-empty="false" class="multiselect-blue"
                                          :options="periodOptions"
                                          :close-on-select="true" placeholder="Select Period" label="name"
                                          track-by="name"
                                          :show-labels="false"/>
                        </div>

                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <div class="form-group">
                        <div class="">
                          <label>Licence Number</label>
                          <input v-model="postProductLicenseData.license_key" type="text" class="form-control"
                                 placeholder="Enter licence Number">
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </div>
              <!--            end licence info section-->
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 pt-0">
          <button type="button" class="btn btn-primary" @click="singlePostProductItem">
            <i class="bx bx-x d-block d-sm-none"></i>
            <span class="d-none d-sm-block">Save Changes</span>
          </button>
          <!-- data-dismiss="modal" -->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import VueMultiselect from "vue-multiselect";
import {mapActions, mapGetters} from "vuex";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
  name: "AddProductItemModal",
  props: ['productId', 'stateId', 'conditionId'],
  mixins: [ShowToastMessage, Loader],
  emits: ['getCreatedData'],
  components: {
    VueMultiselect,
  },
  data() {
    return {
      hasLicence: false,
      selectedLicenceType: {
        value: '',
        name: 'None'
      },
      selectedPeriod: {
        value: '',
        name: 'None'
      },
      licenceTypeOptions: [
        {
          value: '',
          name: 'None'
        },
        {
          value: 0,
          name: 'Subscription'
        },
        {
          value: 1,
          name: 'Period'
        }
      ],
      periodOptions: [
        {
          value: '',
          name: 'None'
        },
        {
          value: '+7 days',
          name: '7 Days'
        },
        {
          value: '+15 days',
          name: '15 Days'
        },
        {
          value: '+30 days',
          name: '30 days'
        },
        {
          value: '+3 months',
          name: '3 months'
        },
        {
          value: '+6 months',
          name: '6 months'
        },
        {
          value: '+1 year',
          name: '1 year'
        },
        {
          value: '+2 years',
          name: '2 years'
        },
        {
          value: '+3 years',
          name: '3 years'
        },
      ],
      postProductLicenseData: {
        customer_id: '',
        product_item_id: '',
        license_type: '',
        period: '',
        license_key: '',
      },
      postProductItemData: {
        product_id: '',
        serial_number: '',
        model_number: '',
        sku: '',
        barcode: '',
        qr_code: '',
        manufacturing_code: '',
        purchase_cost: '',
        condition: '',
        warehouse_id: '',
        state: '',
        vendor_id: '',
      },
      selectedProduct: {
        value: '',
        name: 'None'
      },
      selectedCondition: {
        value: '',
        name: 'None'
      },
      getSettingsParams: {
        type: ['product_item', 'default'],
        key: ['product_item_condition', 'default_state'],
      },
      selectedWarehouse: {
        value: '',
        name: 'None'
      },
      selectedState: {
        value: '',
        name: 'None'
      },
      selectedVendor: {
        value: '',
        name: 'None'
      },
      selectedProductId: '',
      errors: {
        product_id: '',
        vendor_id: '',
        warehouse_id: '',
        state: '',
      },
      getWarehousesParams: {
        status: 1,
        with_relation: ['address','managedBy']
      },

    }
  },
  watch: {
    productId(productId) {
      if (productId) {
        this.selectedProduct = this.productOptions.find(({value}) => value === productId);
      }
    },
    conditionId(conditionId) {
      if (conditionId) {
        this.selectedCondition = this.conditionOptions.find(({value}) => value === conditionId);
      }
    },
    stateId(stateId) {
      if (stateId) {
        this.selectedState = this.stateOptions.find(({value}) => value === stateId);
      }
    },
    selectedCondition() {
      this.postProductItemData.condition = this.selectedCondition.value;
    },
    selectedProduct() {
      this.postProductItemData.product_id = this.selectedProduct.value;
    },
    selectedWarehouse() {
      this.postProductItemData.warehouse_id = this.selectedWarehouse.value;
      // check selected warehouse manager available or not
      this.selectedWarehouseManager = this.$store.getters['appWarehouses/warehouses'].find(({id}) => id === this.selectedWarehouse.value)?.managedBy;
      if(this.selectedWarehouseManager === null){
        this.showToastMessage({message: 'No store manager in the warehouse, please add .', type: 'error'})
      }
    },
    selectedState() {
      this.postProductItemData.state = this.selectedState.value;
    },
    selectedVendor() {
      this.postProductItemData.vendor_id = this.selectedVendor.value;
    },
    selectedLicenceType() {
      this.postProductLicenseData.license_type = this.selectedLicenceType.value;
    },
    selectedPeriod() {
      this.postProductLicenseData.period = this.selectedPeriod.value;
    },
  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',

    }),
    productOptions() {
      let products = this.$store.getters['appProducts/products'].map((type) => {
        let id = type.id;
        let name = type.name;
        return {value: id, name: name};
      });
      return [{value: '', name: 'None'}, ...products];
    },
    conditionOptions() {
      let condition = this.$store.getters['appSettings/settingProductItemCondition'].value.map((item) => {
        let value = item.value;
        let name = item.name;
        return {value: value, name: name};
      });
      return [{value: '', name: 'None'}, ...condition];
    },
    stateOptions() {
      let states = this.$store.getters['appSettings/settingDefaultState'].value;
      return [{value: '', name: 'None'}, ...states];
    },
    warehouseOptions() {
      let warehouses = this.$store.getters['appWarehouses/warehouses'].map((warehouse) => {
        let id = warehouse.id, name = warehouse.name;
        return {value: id, name: name};
      })
      return [{value: '', name: 'None'}, ...warehouses]
    },
    vendorOptions() {
      let vendors = this.$store.getters['appVendors/vendors'].map((vendor) => {
        let id = vendor.id, first_name = vendor?.user?.first_name ?? '', last_name = vendor?.user?.last_name ?? '',
            code = vendor.code;
        return {value: id, name: `${first_name} ${last_name} -code: ${code}`};
      })
      return [{value: '', name: 'None'}, ...vendors]
    },
    isProductLicensesDataFilled() {
      return !!(this.hasLicence && this.postProductLicenseData.license_type !== null && this.postProductLicenseData.period && this.postProductLicenseData.license_key);
    },
  },
  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getProducts: 'appProducts/getProducts',
      getWarehouses: 'appWarehouses/getWarehouses',
      getVendors: 'appVendors/getVendors',

      postProductItem: 'appProductItems/postProductItem',
      postProductLicense: 'appProductLicenses/postProductLicense',
    }),
    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },
    async singlePostProductItem() {
      if(this.selectedWarehouseManager === null){
        this.showToastMessage({message: 'No store manager in the warehouse, please add or select another one', type: 'error'});
        return
      }
      this.loader(true);
      await this.postProductItem({
        ...this.postProductItemData,
        purchase_cost: this.postProductItemData.purchase_cost * 100
      }).then(async (response) => {
        this.loader(false);
        // console.log(response)
        if (response.status === 201) {
          document.querySelector('[data-target="#productItemAddModal"]').click();
          const toastObj = {message: 'Product Item created successful.', type: 'success'};
          this.showToastMessage(toastObj);
          let productItem = this.$store.getters['appProductItems/productItem']

          if (this.isProductLicensesDataFilled) {
            this.postProductLicenseData.product_item_id = productItem.id;
            await this.createProductItemLicenses(this.postProductLicenseData)
          }
          let productLicense = this.$store.getters['appProductLicenses/productLicense']
          delete productLicense.productItem;
          productItem.productLicense = productLicense;
          this.$emit('getCreatedData', productItem);
          this.clear_data();
        }
        if (response.message) {
          this.showToastMessage(response);
        }
        this.errors.product_id = response?.errors?.product_id?.[0] ?? '';
        this.errors.vendor_id = response?.errors?.vendor_id?.[0] ?? '';
        this.errors.warehouse_id = response?.errors?.warehouse_id?.[0] ?? '';
        this.errors.state = response?.errors?.state?.[0] ?? '';
      });
    },
    async createProductItemLicenses(dataObj) {
      this.loader(true);
      return await this.postProductLicense(dataObj).then((response) => {
        this.loader(false);
        return response.status === 201;
      });
    },

    clear_data() {
      this.selectedWarehouse = {value: '', name: 'None'};
      this.selectedVendor = {value: '', name: 'None'};
      this.postProductItemData.vendor_id = '';
      this.postProductItemData.warehouse_id = '';
      this.postProductItemData.serial_number = '';
      this.postProductItemData.model_number = '';
      this.postProductItemData.sku = '';
      this.postProductItemData.barcode = '';
      this.postProductItemData.qr_code = '';
      this.postProductItemData.manufacturing_code = '';
      this.postProductItemData.purchase_cost = '';
      this.selectedLicenceType = {value: '', name: 'None'};
      this.selectedPeriod = {value: '', name: 'None'};
      this.postProductLicenseData.license_key = '';
    },

  },
  async mounted() {
    await this.getProducts();
    await this.getSettingList(this.getSettingsParams);
    await this.getWarehouses(this.getWarehousesParams);
    await this.getVendors({with_relation: ['user']});
  }
}
</script>

<style scoped>

</style>