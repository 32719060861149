<template>
  <div class="modal fade " id="productListModal" tabindex="-1" role="dialog" aria-labelledby="productListModal"
       aria-hidden="true" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog  modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Add Item</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div>
            <div class="users-list-filter px-1 my-2  rounded">
              <div>

                <div class="row">
                  <div class="col-12 col-sm-12 col-md-9  col-lg-9">
                    <input v-model="searchProduct" class="form-control search-product-input-element" type="text"
                           placeholder="Search a product by name or code">
                  </div>
                  <div class="col-12 col-sm-12 col-md-3 col-lg-3 mt-1 mt-md-0">
                    <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0">Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <div class="row pb-2">
                <div class="col-12 text-center">
                  <h4>Product List</h4>
                </div>

              </div>
            </div>

            <div class=" ">
              <div class="flexbox">
                <div class="py-1 product-card" v-for="(product) in products" :key="product.id">
                  <div class="">
                    <div class="px-2 mb-1 product-image">
                      <img :src="product?.images?.[0]" class="img-fluid"
                           alt="">
                    </div>
                    <div class="text-center">
                      <h6 class="mb-0">{{ product.name.toUpperCase() }}</h6>
                      <p class="small text-secondary mb-0">
                        <span class="font-weight-bold"> Code :</span> {{ product.code }}
                      </p>
                      <p class="small text-secondary mb-0">
                        <span class="font-weight-bold"> Category :</span> {{ product.productCategory.name }}
                      </p>
                      <p class="small text-secondary mb-0">
                        <span class="font-weight-bold">Type :</span> {{ product.type }}
                      </p>
                      <div class="">
                        <button type="button" class="btn btn-sm btn btn-primary" data-toggle="modal"
                                data-target="#productConditionsModal" @click="getProductPrices(product.id)">View
                          Items
                        </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <!-- shopping cart side bar -->
            <ProductConditionsModal @getSelectedProductCondition="transferEmitParam($event)"
                                    :single-product="{...singleProductPricesCondition}"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductConditionsModal from "@/views/backEnd/Orders/includes/ProductConditionsModal";

export default {
  name: "ProductListModal",
  props: ['products'],
  data() {
    return {
      singleProductPricesCondition: {},
      searchProduct: ''
    }
  },
  components: {
    ProductConditionsModal,
  },
  methods: {
    getProductPrices(productId) {
      this.singleProductPricesCondition = this.products.find((product) => product.id === productId);
    },
    transferEmitParam(data) {
      this.$emit('selectedProductCondition', data)
    }
  }
}
</script>

<style scoped>
.cart-product-image {
  height: 70px;
  width: 70px;
}

.c-content {
  position: absolute;
  bottom: 40px;
  top: 72px;
  left: 0;
  right: 0;
  overflow-y: scroll;
}

.c-content::-webkit-scrollbar {
  width: 2px !important;
}

.c-content::-webkit-scrollbar-thumb {
  background-color: #5a8dee;
  /* outline: 1px solid slategrey; */
  border-radius: 15px;
}

.c-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}

.c-footer {
  position: absolute;
  bottom: 20px;
  background-color: #fff;
  left: 0;
  right: 0;

}

.floatable-cart {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  /* background-color: rgba(0, 0, 0, 0.2); */
  z-index: 1;
  cursor: pointer;
}

.product-list-image {
  height: 100px;
  width: 100px;
  object-fit: contain;
}

.item-list-lg-section {
  height: 70vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

.item-list-lg-section::-webkit-scrollbar {
  display: none;
}

.product-list-lg-section::-webkit-scrollbar {
  display: none;
}

.product-list-lg-section {
  height: 70vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media only screen and (max-width: 500px) {
  html .navbar-static .app-content .content-wrapper {
    padding: 1.8rem 1rem 0 !important;
    margin-top: 3px;
  }
}

.product-cart-side-bar-open {
  top: 0px;
  right: 0;
  /* left: 30px; */
  width: 450px;
  bottom: 0;
  border-radius: 0.267rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: transparent;
  position: fixed;
  transition: all .3s ease-in-out, background 0s;
  z-index: 8;
  transition: all 0.5s;
  background-color: white;
  min-height: 100vh;
  /* overflow-y: scroll; */
  box-shadow: 0px 2px 2px 1px rgb(199 199 199);

}

.product-cart-side-bar-close {
  top: 0px;
  right: -460px;
  bottom: 0;
  width: 450px;
  border-radius: 0.267rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #fff;
  position: fixed;
  transition: all .3s ease-in-out, background 0s;
  z-index: 8;
  transition: all 0.5s;
  box-shadow: 0px 2px 2px 1px rgb(199 199 199);

}

.service-type-container {
  padding: 10px 20px;
  font-size: 14px !important;
  border-radius: 5px;
  cursor: pointer;
  min-width: 150px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);

}

.product-card {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

}

.product-card:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: all 0.2s ease-in-out;

}

.product-card:hover .product-image {
  transform: scale(1.2);
  transition: all .4s;

}

.product-image {
  transition: all .1s;
  transform: scale(1);
}

.flexbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flexbox > div {
  /*
  1/3  - 3 columns per row
  10px - spacing between columns
*/
  box-sizing: border-box;
  margin: 15px 20px 0 0;
  /* outline: 1px dotted red; */
  width: calc(1 / 6 * 100% - (1 - 1 / 6) * 20px);
}

/*
  align last row columns to the left
  3n - 3 columns per row
*/
.flexbox > div:nth-child(6n) {
  margin-right: 0;
}

.flexbox::after {
  content: '';
  flex: auto;
}

/*
  remove top margin from first row
  -n+3 - 3 columns per row
*/
.flexbox > div:nth-child(-n+6) {
  margin-top: 0;
}

@media (max-width: 400px) and (min-width: 0px) {
  .flexbox > div {
    box-sizing: border-box;
    margin: 15px 20px 0 0 !important;
    /* outline: 1px dotted red; */
    width: calc(1 / 1 * 100% - (1 - 1 / 1) * 20px) !important;
  }

  .flexbox > div:nth-child(1n) {
    margin-right: 0 !important;
  }

  .flexbox > div:nth-child(-n+1) {
    margin-top: 0 !important;
  }
}

@media (max-width: 640px) and (min-width: 399px) {
  .flexbox > div {
    box-sizing: border-box;
    margin: 15px 20px 0 0 !important;
    /* outline: 1px dotted red; */
    width: calc(1 / 2 * 100% - (1 - 1 / 2) * 20px) !important;
  }

  .flexbox > div:nth-child(2n) {
    margin-right: 0 !important;
  }

  .flexbox > div:nth-child(-n+2) {
    margin-top: 0 !important;
  }
}

@media (max-width: 768px) and (min-width: 641px) {
  .flexbox > div {
    box-sizing: border-box;
    margin: 15px 20px 0 0 !important;
    /* outline: 1px dotted red; */
    width: calc(1 / 3 * 100% - (1 - 1 / 3) * 20px) !important;
  }

  .flexbox > div:nth-child(3n) {
    margin-right: 0 !important;
  }

  .flexbox > div:nth-child(-n+3) {
    margin-top: 0 !important;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  .flexbox > div {
    box-sizing: border-box;
    margin: 15px 20px 0 0 !important;
    /* outline: 1px dotted red; */
    width: calc(1 / 4 * 100% - (1 - 1 / 4) * 20px) !important;
  }

  .flexbox > div:nth-child(4n) {
    margin-right: 0 !important;
  }

  .flexbox > div:nth-child(-n+4) {
    margin-top: 0 !important;
  }
}

@media (max-width: 1280px) and (min-width: 1025px) {
  .flexbox > div {

    box-sizing: border-box;
    margin: 15px 20px 0 0;
    /* outline: 1px dotted red; */
    width: calc(1 / 5 * 100% - (1 - 1 / 5) * 20px);
  }

  .flexbox > div:nth-child(5n) {
    margin-right: 0;
  }

  .flexbox > div:nth-child(-n+5) {
    margin-top: 0;
  }
}

@media only screen and (max-width: 500px) {
  .service-type-container {
    min-width: 100px;
    padding: 10px 12px;
    font-size: 12px !important;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);

  }
}

@media only screen and (max-width: 700px) and (min-width: 0px) {
  .product-cart-side-bar-open {
    top: 0px;
    right: 0;
    /* left: 30px; */
    width: 100%;
    bottom: 0;
    border-radius: 0.267rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: transparent;
    position: fixed;
    transition: all .3s ease-in-out, background 0s;
    z-index: 8;
    transition: all 0.5s;
    background-color: white;
    min-height: 100vh;

    /* overflow-y: scroll; */
    box-shadow: 0px 2px 2px 1px rgb(199 199 199);

  }

  .product-cart-side-bar-close {
    top: 0px;
    right: -100%;
    bottom: 0;
    width: 100%;
    border-radius: 0.267rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: #fff;
    position: fixed;
    transition: all .3s ease-in-out, background 0s;
    z-index: 8;
    transition: all 0.5s;
    box-shadow: 0px 2px 2px 1px rgb(199 199 199);

  }

  .single-edit-btn {
    cursor: pointer;
  }

}
</style>